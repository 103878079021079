@value shared: "../../values/shared.module.css";
@value primary, secondary, tertiary, critical-width, back1 from shared;

.home-layout {
  min-height: 100vh;
  min-width: 320px;
  /* Keep this for all screens */
  width: 100%;
  padding: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  box-sizing: border-box;
  overflow-x: visible;
  overflow-y: hidden;
  background-color: back1;
  padding-bottom: 100px;
}

.home-layout * {
  font-family: "Rubik", sans-serif;
}

@media (min-width: critical-width) {
  .home-layout {
    justify-content: unset;
    align-items: unset;
    min-inline-size: critical-width;
    padding: 32px;
    gap: 32px;
  }
}