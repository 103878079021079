.react-datepicker-wrapper {
  font-size: 16px;
  margin-left: auto;
}
.react-datepicker-wrapper > div > input {
  text-align: center;
}
.react-datepicker__header {
  padding-top: 0.8em;
}
.react-datepicker__input-container {
  display: flex !important;
  align-items: center !important;
}
.react-datepicker__input-container > input {
  padding-left: 30px !important;
  border: 1px solid var(--tertiary) !important;
  border-radius: 12px !important;
}
.react-datepicker__input-container > input:focus {
  border-color: var(--primary) !important;
  outline: none;
}
.react-datepicker__month-container {
  font-family: "Rubik" !important;
  font-weight: 400 !important;
}
.react-datepicker__month {
  margin: 0.4em 1em;
}
.react-datepicker__day-name,
.react-datepicker__day {
  width: 1.9em;
  line-height: 1.9em;
  margin: 0.166em;
}
.react-datepicker__day:hover {
  background-color: var(--tertiary) !important;
}
.react-datepicker__day--keyboard-selected {
  background-color: inherit !important;
}
.react-datepicker__current-month {
  font-size: 1em;
}
.react-datepicker__navigation--previous {
  border-right-color: #ccc;
  left: 0.5em;
}
.react-datepicker__navigation--next {
  border-left-color: #ccc;
  right: 0.5em;
}
.react-datepicker__calendar-icon {
  fill: var(--primary) !important;
  padding-left: 8px !important;
}
.react-datepicker__day--selected {
  background-color: var(--primary);
}
