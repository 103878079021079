@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700;800;900&display=swap");
@value shared: "../../../values/shared.module.css";
@value primary, secondary, tertiary, critical-width from shared;

.container {
  padding: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;
  max-width: 400px;
  width: 100%;
}

.label {
  font-family: "Rubik", sans-serif;
  font-weight: 300;
  font-size: 14px;
  color: rgba(6, 33, 2, 1);
  opacity: 80%;
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.input {
  border: 1px solid tertiary;
  border-radius: 12px;
  padding: 8px 12px;
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  font-size: 16px;
  color: rgba(6, 33, 2, 1);
  opacity: 80%;
}

.input:focus {
  outline: none;
  border-color: primary;
}

.buttons {
  display: flex;
  flex-direction: column;
  gap: 16px;
  /* Adjust gap between buttons */
  justify-content: center;
  /* Center the buttons */
  flex-wrap: wrap;
  /* Allow wrapping if needed on smaller screens */
}

.button {
  min-width: 140px;
  /* Ensure buttons have enough width */
  padding: 12px 24px;
  background: rgba(255, 255, 255, 1);
  border: 1px solid tertiary;
  border-radius: 16px;
  font-family: "Rubik", sans-serif;
  font-size: 18px;
  font-weight: 500;
  color: rgb(6, 33, 2);
  cursor: pointer;
}

.button:hover {
  background-color: rgba(205, 236, 198, 0.5);
}

.mobile-extra-buttons {
  display: none;
}

@media (max-width: 1160px) {
  .mobile-extra-buttons {
    display: flex;
    width: 80%;
    justify-content: space-between;
    flex-direction: row;
  }
}