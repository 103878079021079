html,
body {
  margin: unset;
  min-inline-size: unset;
  box-sizing: border-box;
  /* Changed to border-box for consistent sizing */
}

.landing-page {
  background-color: #FFFFF9;
  font-family: "Rubik";
}

.hero {
  background-image: url("../../images/landing-background.jpg");
  background-size: cover;
  background-position: center;
  min-height: 100vh;
  /* Changed to min-height for flexibility */
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.hero-info {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 55%;
  color: #062102;
  font-weight: 400;
  position: relative;
  z-index: 1;
  padding-left: 20vw;
}

.hero-info-header {
  text-align: left;
  line-height: 120%;
  max-width: 553px;
  letter-spacing: 0%;
  font-weight: 700;
  font-size: 64px;
}

.hero-info-text {
  text-align: left;
  line-height: 150%;
  letter-spacing: 0%;
  max-width: 373px;
  font-weight: 400;
  font-size: 24px;
}

.hero-info-header .highlight-text {
  color: #548054;
}

.button {
  padding: 16px 24px;
  border-radius: 16px;
  border: none;
  line-height: 24px;
  font-size: 16px;
  width: 40%;
  font-weight: 600;
  text-align: center;
  outline: none;
}

.hero-button-guide {
  background-color: #548054;
  color: #F6F6F6;
}

.hero-button-platform {
  background-color: #CDECC6;
  color: #548054;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.button:hover {
  box-shadow: 0 2px 6px #b1b9b0;
}

.help {
  background-color: #F4FBF2;
  padding: 4rem 0;
}

.help .container {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.help .highlight-text {
  color: #548054;
}

.help-header {
  width: 80%;
  text-align: left;
  line-height: 150%;
  font-size: 36px;
  font-weight: 700;
}

.help-text {
  width: 46%;
  text-align: left;
  line-height: 150%;
  font-size: 20px;
  font-weight: 400;
}

.contactInfo {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.container h2 {
  font-weight: 700 !important;
  color: #062102;
}

.footer {
  background-color: #CDECC6;
}

.footer .container {
  color: #062102;
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 40px 0;
  width: 100%;
  font-weight: 400;
  background-color: inherit;
}

.muted-footer-text {
  color: #10260DB2;
}

/* Media Queries for Responsiveness */

@media(max-width: 1380px) {
  .hero-info-header {
    font-size: 48px;
  }

  .hero-info-text {
    font-size: 20px;
  }

  .button {
    width: 50%;
  }
}

@media(max-width: 1034px) {
  .hero-info {
    padding-left: 10vw;
  }

  .hero-info-header {
    font-size: 40px;
  }

  .hero-info-text {
    font-size: 18px;
  }

  .button {
    width: 60%;
  }
}

@media (max-width: 768px) {
  .hero {
    min-height: 80vh;
    /* Reduce height */
    padding: 2rem 0;
    /* Adjust padding */
  }

  .hero-info {
    width: 80%;
    /* Wider on smaller screens */
    padding-left: 5vw;
    /* Reduce padding */
    gap: 12px;
  }

  .hero-info-header {
    font-size: 40px;
    /* Smaller font */
    max-width: 100%;
    /* Full width */
  }

  .hero-info-text {
    font-size: 18px;
    max-width: 70%;
  }

  .button {
    width: 50%;
    /* Wider buttons */
    padding: 12px 20px;
    font-size: 14px;
  }

  .help {
    padding: 2rem 0;
    /* Reduce padding */
  }

  .help-header {
    width: 90%;
    font-size: 28px;
  }

  .help-text {
    width: 80%;
    font-size: 16px;
  }

  .footer .container {
    padding: 20px 0;
    /* Reduce padding */
  }
}

@media (max-width: 425px) {
  .hero {
    min-height: 70vh;
    /* Further reduce height */
    justify-content: center;
    /* Center content */
  }

  .hero-info {
    width: 90%;
    padding-left: 0;
  }

  .hero-info-header {
    font-size: 28px;
    line-height: 130%;
  }

  .hero-info-text {
    font-size: 16px;
    max-width: 70%;
  }

  .d-flex {
    flex-direction: column;
    /* Stack buttons */
    gap: 12px;
    justify-content: center;
    align-items: center;
  }

  .button {
    width: 80%;
    /* Full-width buttons */
    padding: 10px 16px;
    font-size: 14px;
  }

  .help-header {
    width: 100%;
    font-size: 24px;
    text-align: center;
  }

  .help-text {
    width: 90%;
    font-size: 14px;
    text-align: center;
  }

  .contactInfo {
    gap: 4px;
  }
}