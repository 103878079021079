.reset-password-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 80px 64px;
  gap: 10px;
  min-inline-size: 304px;
  max-inline-size: 504px;
  max-block-size: 356px;
  background: #ffffff;
  border-radius: 24px;
  flex: none;
  order: 0;
  flex-grow: 0;
  box-shadow: 0px 15px 34px 0px rgba(228, 228, 212, 1),
    0px 61px 61px 0px rgba(228, 228, 212, 0.09),
    0px 138px 83px 0px rgba(228, 228, 212, 0.05),
    0px 245px 98px 0px rgba(228, 228, 212, 0.01),
    0px 382px 107px 0px rgba(228, 228, 212, 0);
}

.header {
  font-family: Rubik;
  font-weight: 700;
  font-size: 32px;
}

.reset-password-form input {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  align-content: flex-start;
  gap: 0px 2px;
  border: none;
  outline: none;
  flex: none;
  order: 0;
  flex-grow: 0;
}
.continue-button {
  inline-size: 100%;
  block-size: 56px;
  color: rgba(246, 246, 246, 1);
  background-color: rgba(84, 128, 84, 1);
  border: none;
  border-radius: 16px;
}
.inside-form {
  display: flex;
  flex-direction: column;
  inline-size: 100%;
  gap: 16px;
}
.success-message {
  font-family: Rubik;
  font-weight: 600;
  text-align: center;
  font-size: 32px;
}

@media (max-width: 1100px) and (min-width: 769px) {
  .header {
    font-size: 28px;
  }
}
@media (max-width: 768px) {
  .header {
    font-size: 24px;
  }
  .reset-password-form {
    padding: 16px;
    inline-size: 90%;
  }
}
@media (max-width: 400px) {
  .header {
    font-size: 20px;
  }
}
