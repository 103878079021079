@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700;800;900&display=swap");
@value shared: "../../../values/shared.module.css";
@value primary, secondary, tertiary, critical-width from shared;

.header {
  display: flex;
  inline-size: 100%;
  padding: 16px 32px;
  align-items: center;
  color: black;
  background: rgba(255, 255, 255, 1);
  border: 1px solid tertiary;
  border-radius: 24px;
  flex-wrap: wrap;
  --group-gap: 20px;
  /* Custom property for manual gap control */
}

.left-group,
.right-group {
  display: flex;
  align-items: center;
}

.left-group {
  gap: 30px;
  margin-right: var(--group-gap);
  /* Manual gap between left and right groups */
}

.right-group {
  gap: 20px;
  margin-left: auto;
  /* Pushes right-group to the right edge */
}

.logo {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.header-buttons-group-one {
  display: flex;
  align-items: center;
  gap: 10px;
}

.header-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin: 0;
}

.header-buttons-last {
  display: flex;
  align-items: center;
  gap: 5px;
  min-width: 150px;
}

.button {
  min-block-size: 48px;
  min-inline-size: 140px;
  inline-size: 12vw;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 1);
  border: 1px solid tertiary;
  border-radius: 16px;
  font-size: 18px;
  text-decoration: none;
  padding: 0px 8px;
  color: rgb(6, 33, 2);
  font-family: "Rubik", sans-serif;
  font-weight: 500;
}

.button-active:focus {
  outline: none;
}

.button-active {
  background-color: tertiary;
}

.a:hover {
  text-decoration: underline;
}

.profile-dropdown {
  color: rgba(6, 33, 2, 1);
  font-family: "Rubik";
  font-weight: 400;
  font-size: 14px;
  line-height: 16.94px;
  text-align: left;
  gap: 8px;
}

.profile-dropdown a:hover {
  text-decoration: underline;
}

.profile-dropdown .option:active {
  background-color: primary;
  font-family: "Rubik";
  font-weight: 400;
}

.rounded-circle {
  inline-size: 32px;
  block-size: 32px;
  border-radius: 50%;
  border: 2px solid primary;
  object-fit: cover;
}

.profile-dropdown a::after {
  display: none;
}

.profile-selection-form {
  display: flex;
  max-width: 220px;
  flex-direction: column;
  align-items: flex-start;
  background: #ffffff;
  border-radius: 16px;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.profile-selection-form.open {
  border-radius: 0px !important;
}

.profile-selection-form.closed {
  border-radius: 16px !important;
}

.exit-button {
  min-inline-size: 22px;
  color: primary;
  cursor: pointer;
}

.languageSelector {
  position: relative;
  display: inline-block;
  inset-inline-end: 0;
  outline: none;
}

.dropdownToggle {
  background: none;
  border: none;
  font-size: 16px;
  cursor: pointer;
  outline: none;
}

.dropdownMenu {
  position: absolute;
  top: 100%;
  left: auto;
  right: 0;
  background-color: white;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  z-index: 10;
}

.flagButton {
  display: flex;
  align-items: center;
  padding: 8px 12px;
  background: none;
  border: none;
  cursor: pointer;
  width: 100%;
  text-align: left;
}

.flagButton img {
  width: 20px;
  height: 20px;
  margin-right: 8px;
}

.flagButton:hover {
  background-color: #f0f0f0;
}

@media (max-width: 1200px) {
  .header {
    display: none;
  }
}


@media (min-width: 1360px) {
  .header {
    padding: 16px 48px;
  }

  .left-group {
    gap: 80px;
    /* Increase gap within left-group */
    margin-right: var(--group-gap);
  }

  .right-group {
    gap: 20px;
    /* Increase gap within right-group */
  }

  .header-buttons-group-one {
    gap: 20px;
    /* Increase gap within left button group */
  }

  .header-buttons {
    gap: 20px;
    /* Increase gap between buttons */
  }

  .header-buttons-last {
    gap: 10px;
    /* Increase gap within last group */
    min-width: 180px;
    /* Slightly wider for larger screens */
  }

  .button {
    inline-size: 12vw;
    /* Fixed larger width instead of 12vw */
    font-size: 20px;
    padding: 0px 16px;
    /* Increase padding for wider buttons */
  }
}

@media (min-width: 1600px) {
  .button {
    inline-size: 13.5vw;
    font-size: 22px;
  }
}

@media (min-width: 1800px) {
  .button {
    inline-size: 14vw;
  }
}