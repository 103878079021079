.overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal {
    background: white;
    padding: 25px;
    border-radius: 10px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
    text-align: center;
    max-width: 400px;
    width: 100%;
    border: 2px solid #28a745;
    /* Green border */
}

.warningIcon {
    font-size: 36px;
    color: #28a745;
    /* Green icon */
    margin-bottom: 15px;
}

.message {
    font-size: 16px;
    color: #333;
    margin: 0 0 20px 0;
    line-height: 1.5;
}

.buttonContainer {
    display: flex;
    justify-content: center;
    gap: 15px;
}

.confirmButton {
    background-color: #28a745;
    /* Green background */
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 6px;
    cursor: pointer;
    font-weight: bold;
    transition: background-color 0.2s;
}

.confirmButton:hover {
    background-color: #218838;
    /* Darker green on hover */
}

.cancelButton {
    background-color: #e9ecef;
    /* Light gray */
    color: #333;
    border: none;
    padding: 10px 20px;
    border-radius: 6px;
    cursor: pointer;
    font-weight: bold;
    transition: background-color 0.2s;
}

.cancelButton:hover {
    background-color: #ced4da;
    /* Slightly darker gray on hover */
}