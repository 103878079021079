@value shared: "../../../values/shared.module.css";
@value primary, secondary, tertiary, critical-width from shared;

.container {
  max-width: 100% !important;
  /* Override Bootstrap's default max-width */
  width: 100% !important;
  padding: 0 !important;
}

.header-text {
  font-family: "Rubik", sans-serif;
  font-weight: 700;
  color: rgba(6, 33, 2, 1);
  opacity: 80%;
}

.edit-button {
  background-color: inherit;
  border: none;
  outline: none;
}

.info-field-container {
  display: flex;
  flex-direction: column;
  gap: 4px;
  flex-grow: 1;
}

.info-field-container>div {
  display: flex;
  flex-direction: column;
}

.info-top-box {
  display: flex;
  border-radius: 16px;
  padding: 8px 12px 8px 12px;
  gap: 12px;
  border: 1px solid tertiary;
  width: 100%;
}

.info-box {
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  gap: 12px;
  width: 100%;
}

.info-field {
  display: flex;
  width: 100%;
  align-items: center;
  border-radius: 12px;
  padding: 8px 12px 8px 12px;
  gap: 12px;
  border: 1px solid tertiary;
}

.info-field>div {
  display: flex;
  flex-direction: column;
  gap: 4px;
  flex-grow: 1;
}

.info-field-label {
  padding: inherit;
  font-family: "Rubik", sans-serif;
  font-weight: 300;
  font-size: 12px;
  line-height: 14.22px;
  color: rgba(6, 33, 2, 1);
  opacity: 80%;
}

.gender-select-label {
  padding-left: 12px;
  font-family: "Rubik", sans-serif;
  font-weight: 300;
  font-size: 12px;
  line-height: 14.22px;
  color: rgba(6, 33, 2, 1);
  opacity: 80%;
}

.info-field-input {
  border: none !important;
  padding: inherit;
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  font-size: 16px;
  width: 100%;
  line-height: 24px;
  color: rgba(6, 33, 2, 1);
  opacity: 80%;
  flex-grow: 1;
}

.info-field-input:focus {
  box-shadow: none !important;
  outline: none !important;
}

.client-info {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 24px;
  width: 100%;
  text-align: left;
  background-color: rgba(255, 255, 255, 1);
  border-radius: 16px;
  border: 1px solid tertiary;
  box-shadow: 0px 24px 36.6px 0px rgba(0, 0, 0, 0.1),
    0px 0px 68.1px 0px rgba(0, 0, 0, 0.09),
    0px 212px 127px 0px rgba(228, 228, 212, 0.05),
    0px 378px 151px 0px rgba(228, 228, 212, 0.01),
    0px 590px 165px 0px rgba(228, 228, 212, 0);
}

.dietary-info {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.card-container {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  row-gap: 16px;
  padding: 0px !important;
  margin: 0 !important;
}

.card-narrow-container {}

.activity-dropdown {
  padding: 0 12px 4px !important;
  height: 56px;
}

/* Container for the dropdown */
.activity-dropdown {
  position: relative;
  /* Ensure proper positioning of dropdown items */
}

.dropdown-field {
  background-color: #ffffff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  cursor: pointer;
  transition: border-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
}

.dropdown-field:focus {
  border-color: #80bdff;
  outline: none;
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.25);
}

.dropdown-field::-ms-expand {
  display: none;
  /* Hide default dropdown arrow in IE */
}

.card-col {
  padding: 0 4px !important;
}

.card {
  border: 1px solid secondary !important;
  border-radius: 16px !important;
  align-items: center;
}

.card-body {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 24px;
}

.card-text {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100% !important;
  color: rgba(6, 33, 2, 1);
  text-align: center;
  font-size: 16px;
}

.card-label {
  width: 100%;
  color: rgba(6, 33, 2, 1);
  font-weight: 500;
  font-family: "Rubik", sans-serif;
  line-height: 24px;
  font-size: 16px;
  opacity: 80%;
}

.card-input {
  display: flex;
  text-align: center;
  border: none;
  padding: inherit;
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: rgba(6, 33, 2, 1);
  opacity: 80%;
}

.card-input:focus {
  outline: none;
}

.pcf-input {
  inline-size: 30px;
}

.dietary-data {
  display: flex;
  flex-direction: row;
  justify-content: end;
  gap: 16px;
  padding: 24px;
  width: 100%;
  text-align: left;
  background-color: rgba(255, 255, 255, 1);
  border-radius: 16px;
  border: 1px solid tertiary;
  margin: 0 !important;
  box-shadow: 0px 24px 36.6px 0px rgba(0, 0, 0, 0.1),
    0px 0px 68.1px 0px rgba(0, 0, 0, 0.09),
    0px 212px 127px 0px rgba(228, 228, 212, 0.05),
    0px 378px 151px 0px rgba(228, 228, 212, 0.01),
    0px 590px 165px 0px rgba(228, 228, 212, 0);
}

.rounded-circle {
  width: 64px;
  height: 64px;
  border-radius: 50%;
  border: 2px solid primary;
  object-fit: cover;
}

.header-1 {
  text-align: left;
  font-size: 24px;
  line-height: 24px;
  padding: 0 !important;
}

.label {
  padding-left: 10px;
  line-height: 26px;
}

.input {
  border: none;
  height: 24px;
}

.recommended-limit {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 24px;
  width: 100%;
  text-align: left;
  background-color: rgba(255, 255, 255, 1);
  border-radius: 16px;
  border: 1px solid tertiary;
  margin: 0;
}

.editable {
  border: 1px solid secondary !important;
  /* box-shadow: 0 0 0 0.5px secondary inset !important; */
}

.gender-select-warpper {
  display: flex;
  flex-direction: column;
  justify-content: start;
  border-radius: 12px;
  gap: 12px;
  border: 1px solid tertiary;
}

.input-wrapper {
  display: flex;
  flex-direction: row;
}

.input-wrapper::after {
  content: "%";
}

.input-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.input-container span {
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: rgba(6, 33, 2, 1);
  opacity: 80%;
}

@media (min-width: critical-width) {
  .left-side {
    padding-right: 8px !important;
  }

  .right-side {
    padding-left: 8px !important;
  }
}

@media (max-width: critical-width) {
  .main-row {
    row-gap: 8px;
  }
}

@media (max-width: 440px) {
  .card-body {
    padding: 8px !important;
  }

  .card-text {
    gap: 4px;
  }

  .card-label {
    font-size: 14px;
  }
}

@media (max-width: 364px) {

  .dietary-data,
  .client-info {
    padding: 16px;
  }

  .dietary-data *,
  .client-info * {
    font-size: 14px !important;
  }

  .header-1 {
    font-size: 20px !important;
  }

  .card-body {
    padding: 2px !important;
  }

  .card-text {
    gap: 4px;
  }

  .card-label {
    font-size: 14px;
  }
}