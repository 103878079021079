@value shared: "../../../values/shared.module.css";
@value critical-width, primary, back1 from shared;

.profile-list-container {
  font-family: "Rubik";
  font-weight: 400;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 32px;
  text-align: left;
  background-color: rgba(255, 255, 255, 1) !important;
  border-radius: 24px;
  border-color: rgba(205, 236, 198, 1) !important;
  border: 1px solid;
  box-shadow: 0px 24px 36.6px 0px rgba(0, 0, 0, 0.1),
    0px 0px 68.1px 0px rgba(0, 0, 0, 0.09),
    0px 212px 127px 0px rgba(228, 228, 212, 0.05),
    0px 378px 151px 0px rgba(228, 228, 212, 0.01),
    0px 590px 165px 0px rgba(228, 228, 212, 0);
}

SearchBar {
  width: 100%;
}

.pagination-controls {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.pagination-controls button {
  margin: 0 10px;
  font-size: 18px;
  border: 2px #acd1a3 solid;
  outline: none;
  border-radius: 12px;
  background: rgb(255, 255, 255);
}

.pagination-controls button:hover {
  background-color: #acd1a3;
}

.profile-search-row {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 8px;
}

.add-profile-btn-wrapper {
  border: none;
  background-color: inherit;
}

.add-profile-btn {
  color: #548054;
}

@media (max-width: critical-width) {
  .profile-list-container {
    padding: 4px;
    background-color: inherit !important;
    border: none;
    box-shadow: none;
    gap: 16px;
  }

  .add-profile-btn-wrapper {
    display: none;
  }
}